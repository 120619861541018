/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from 'react';
import ProjectDataContext from './context';
import initialState from './initialState';
import Templates from '../../data/Templates';
import RalColors from '../../data/RalColors';
import {
  deleteProject,
  copyTemplate,
  getParameterChoices,
  getPublicIp,
} from '../../api';
import reducer from './reducer';
import ProjectService from '../../services/ProjectService';
import { getCurrentDate } from '../../utils';
import Constants from '../../constants/index';

let LAST_QUERY = 0;

const ProjectDataProvider = ({ children }) => {
  const [projectData, dispatch] = useReducer(reducer, initialState);
  const [copiedProject, setCopiedProject] = useState(null);
  
  const copyProject = (template, lastQuery) => {
    if (projectData.projectId !== null) {
      deleteProject(projectData.projectId);
    }

    dispatch({ type: 'clear_project' });
    copyTemplate(Templates[template - 1].id, {
      newName: `${Templates[template - 1].name} / ${projectData.projectName}`,
      customerName: '',
    })
      .then((response) => {
        if (lastQuery === LAST_QUERY)
          setCopiedProject({ ...response, lastQuery });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const setGlasses = () => {
    const { sides } = copiedProject.targetInfo.drawings[0];
    const namesGlasses = projectData.currentTemplate === 1 ? ['glass', 'lower_glass'] : ['glass'];
    namesGlasses.map((glassType) => {
      const doorType = projectData.currentTemplate === 1 ? Constants.FRAMED_SLIDE_DOOR : Constants.FRAMELESS_SLIDE_DOOR;
      const result = getParameterChoices(sides.result[0].id, glassType, doorType)
      .then((glasses) => {
        dispatch({ type: glassType, payload: glasses });
      })
      .catch((error) => {
        console.error(error);
      });
      return result;
    });    
  };

  const setVedin = () => {
    const { sides } = copiedProject.targetInfo.drawings[0];
    const namesHandles = ['handle_left', 'handle_middle', 'handle_right'];
    namesHandles.map((side) => {
      const doorType = projectData.currentTemplate === 1 ? Constants.FRAMED_SLIDE_DOOR : Constants.FRAMELESS_SLIDE_DOOR;
      const result = getParameterChoices(sides.result[0].id, side, doorType)
      .then((locks) => {
        dispatch({ type: side, payload: locks });
      })
      .catch((error) => {
        console.error(error);
      });
      return result;
    }); 
  };

  const setLocks = () => {
    const { sides } = copiedProject.targetInfo.drawings[0];
    const namesHandles = ['lock_left', 'lock_middle', 'lock_right'];
    namesHandles.map((side) => {
      const doorType = projectData.currentTemplate === 1 ? Constants.FRAMED_SLIDE_DOOR : Constants.FRAMELESS_SLIDE_DOOR;
      const result = getParameterChoices(sides.result[0].id, side, doorType)
      .then((locks) => {
        dispatch({ type: side, payload: locks });
      })
      .catch((error) => {
        console.error(error);
      });
      return result;
    }); 
  };

  const setColors = () => {
    const { sides } = copiedProject.targetInfo.drawings[0];
    const doorType = projectData.currentTemplate === 1 ? Constants.FRAMED_SLIDE_DOOR : Constants.FRAMELESS_SLIDE_DOOR;
    const result = getParameterChoices(sides.result[0].id, "hole_color", doorType, true)
      .then((colors) => {
        const newColors = addHexColors(colors);
        dispatch({ type: "hole_color", payload: newColors });
      })
      .catch((error) => {
        console.error(error);
      });
    return result;
  };

  const addHexColors = (colors) => {
    colors.map((col) => {
      const color = col;
      const element = RalColors.colors.filter(
        (rcol) => rcol.name === color.value
      );
      const hex = element ? element[0].hex : 'F6F6F6';
      color.value = col.value;
      color.label = col.label;
      color.hex = hex;
      color.show = element ? element[0].show : false;
      return color;  
    });
    return colors.filter(
      (rcol) => rcol.show
    );
  };

  const handleProjectData = () => {
    const newProjectId = copiedProject.project;
    const { shareId } = copiedProject.targetInfo;

    dispatch({ type: 'share_id', payload: shareId });
    dispatch({ type: 'project_id', payload: newProjectId });

    const {
      drawing,
      sides,
      productDefinitions
    } = copiedProject.targetInfo.drawings[0];

    /* First time, lower glass has the same color from upper one */
    if(productDefinitions.result[0].parameters.split_height !== null) {
      productDefinitions.result[0].parameters.lower_glass = productDefinitions.result[0].parameters.glass;
    }
    
    setGlasses(); setVedin(); setLocks(); setColors();

    const systemWidth = ProjectService.getSystemWidth(sides.result);
    const systemHeight = sides.result[0].height;

    dispatch({ type: 'loading_model', payload: false });
    dispatch({ type: 'loading_cost', payload: false });
    dispatch({ type: 'product', payload: productDefinitions.result[0] });
    dispatch({ type: 'side_id', payload: sides.result[0].id });
    dispatch({
      type: 'drawing',
      payload: { drawingId: drawing.id, systemWidth, systemHeight },
    });
  };

  useEffect(() => {
    getPublicIp()
      .then((data) => {
        dispatch({
          type: 'project_name',
          payload: `${data.ip} / ${getCurrentDate()}`,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (projectData.projectName === null) return;
    LAST_QUERY += 1;
    copyProject(projectData.currentTemplate, LAST_QUERY);
  }, [projectData.currentTemplate, projectData.projectName]);

  useEffect(() => {
    if (copiedProject === null) return;    
    if (LAST_QUERY === copiedProject.lastQuery) handleProjectData();
  }, [copiedProject]);

  useEffect(() => {
    if(projectData.product === null) return;
    if(projectData.hasChanged && !projectData.isLoadingModel){
      ProjectService.requestProductInfo(projectData.product.id)
      .then((product)=>{
        /* Lower glass has the same color from upper one */
        if(product.parameters.split_height !== null && product.parameters.lower_glass === null) {
          product.parameters.lower_glass = product.parameters.glass; // eslint-disable-line no-param-reassign
        }
        dispatch({ type: 'product', payload: product });
      });
      dispatch({ type: 'has_changed', payload: false });
    }    
  }, [projectData.hasChanged, projectData.isLoadingModel]);

  useEffect(() => {
    if(projectData.product !== null && 
      projectData.glassOptionsUpper.length > 0 && 
      projectData.colorOptions.length > 0) {
        if (projectData.currentTemplate === 1 && projectData.glassOptionsLower.length > 0)
          dispatch({ type: 'has_loaded_options', payload: true });
        else if (projectData.currentTemplate === 2)
          dispatch({ type: 'has_loaded_options', payload: true });
    }
  }, [projectData.product, projectData.glassOptionsUpper, projectData.glassOptionsLower, projectData.colorOptions]);

  return (
    <ProjectDataContext.Provider
      value={{
        projectData,
        dispatch,
      }}
    >
      {children}
    </ProjectDataContext.Provider>
  );
};

export default ProjectDataProvider;

export const scrollToBottom = () => {
  const el = document.getElementById("parameters-container");
  el.scrollTop = el.scrollHeight;
  window.scroll(0, 10000);
};

export const getCurrentDate = () => {
  const date = new Date();
  return `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
};

export const mapColorToIndex = (colorName, ralColors) => {
  const arrayLength = ralColors.colors.length;
  for (let i = 0; i < arrayLength; i += 1) {
    if (ralColors.colors[i].name === colorName) return i;
  }
  return null;
};

export const productDefinitionQueryBody = (productType, parameters, type = 'parameters', flag = false) => {
  if (flag) {
    return ({
      type: productType,
      ...parameters,
    });
  } 
  return ({
    type: productType,
    [type]: {
      ...parameters,
    },
  });
};

export const productDefinitionQueryBodyMultiple = (productType, parameters, ids, type = 'parameters') => {

  const response = [];

  for (let i = 0; i < parameters.length; i += 1) {
    response.push({
      type: productType,
      id: ids[i],
      [type]: {
        ...parameters[i],
      },
    })
  }

  return response;
};



